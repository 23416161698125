import styles from "./styles.module.scss";
import { Header } from "./Header";
import { Elements } from "./Elements";
import { Canva } from "./Canva";
import { useCanvaStore } from "src/store/canva/canva.state";
import { useState } from "react";
import ModalBox from "@components/ModalCanva";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Layout, PlusCircle } from "lucide-react";
import { presets } from "src/store/canva/canva.const";

const getTitle = (step: number) => {
  switch (step) {
    case 0:
      return "Welcome to canvas!";
    case 1:
      return "Select a preset";
    default:
      return "Welcome to canvas!";
  }
};

export const CanvasEditor: React.FC = () => {
  const nav = useNavigate();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedPreset, setSelectedPreset] = useState("");
  const [step, setStep] = useState(0);

  const { clearCanvas, addElement, setIsOpenElements } = useCanvaStore();

  const onCustomClick = () => {
    setIsFirstRender(false);
    setIsOpenElements(true);
  };

  const selectPreset = () => {
    const selectedPresetObj = presets.find(
      (preset) => preset.name === selectedPreset,
    );

    if (!selectedPresetObj) return;

    clearCanvas();

    selectedPresetObj.elements.forEach((element) => {
      addElement(element);
    });

    setIsOpenElements(false);
    setIsFirstRender(false);
  };

  const handleClose = () => {
    if (window.history.state && window.history.state.idx > 0) {
      nav(-1);
    } else {
      nav("/");
    }
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.wrapper}>
        <Elements />
        {isFirstRender ? (
          <ModalBox
            isOpen={isFirstRender}
            onClose={handleClose}
            title={getTitle(step)}
            className={classNames(
              styles.modal,
              step === 0 && styles.modal__first,
            )}
            maskClosable={false}
          >
            {step === 0 && (
              <>
                <p>
                  Canvas is the first tool for real time collaboration of
                  ecommerce Growth, Operations and Management teams to solve
                  strategic planning and optimization tasks 80%+ faster than
                  before
                </p>
                <h3>Select a way to get started:</h3>
                <div className={styles.buttons}>
                  <button className={styles.button} onClick={onCustomClick}>
                    <div className={styles.icon}>
                      <PlusCircle color="#000" />
                    </div>
                    <div className={styles.text}>
                      <h4>Custom</h4>
                      <p>Build a custom canvas from scratch</p>
                    </div>
                  </button>
                  <button className={styles.button} onClick={() => setStep(1)}>
                    <div className={styles.icon}>
                      <Layout color="#000" />
                    </div>
                    <div className={styles.text}>
                      <h4>Preset</h4>
                      <p>Start with preselected set of elements & blocks</p>
                    </div>
                  </button>
                </div>
              </>
            )}
            {step === 1 && (
              <div className={styles.presets}>
                <div className={styles.presets__container}>
                  {presets.map((preset) => (
                    <button
                      className={classNames(
                        styles.preset,
                        selectedPreset === preset.name &&
                          styles.preset__selected,
                      )}
                      key={preset.name}
                      onClick={() => setSelectedPreset(preset.name)}
                    >
                      <img src={preset.previewImage} alt={preset.name} />
                      <p>{preset.name}</p>
                    </button>
                  ))}
                </div>
                <div className={styles.nav}>
                  <button
                    className={classNames(styles.button, styles.button__back)}
                    onClick={() => setStep(0)}
                  >
                    Back
                  </button>
                  <button
                    className={classNames(styles.button, styles.button__next)}
                    onClick={() => selectPreset()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </ModalBox>
        ) : (
          <Canva />
        )}
      </div>
    </div>
  );
};
